import React from 'react';
// import '../../assets/styles/Footer.css'

const Footer = () => {
  return (
    <footer className="footer_section">
         
<section className="info_section">
  <div className="container">
    <h4>Get In Touch</h4>
    <div className="row">
      <div className="col-lg-10 mx-auto">
        <div className="info_items">
          <div className="row">
            <div className="col-md-3">
            <a href="https://www.google.com/maps/place/August-L%C3%A4mmle-Stra%C3%9Fe+21,+72766+Reutlingen,+Germany/@48.4863784,9.2279331,577m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4799f25cef66b9e5:0x4286d4900b452dab!8m2!3d48.4863784!4d9.230508!16s%2Fg%2F11j20cwxxx?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D">
                <div className="item">
                  <div className="img-box">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </div>
                  <p>August-Lämmle-Straße 21, 72766 Reutlingen</p>
                </div>
              </a>
              
            </div>
            <div className="col-md-3">
                <div className="item">
                  <div className="img-box">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <p>+49 15754652558</p>
                </div>
              
            </div>
            <div className="col-md-3">
            
                <div className="item">
                  <div className="img-box">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </div>
                  <p>simnica-shk@web.de</p>
                </div>
             
            </div>
            <div className="col-md-3">
              <a href="https://www.instagram.com/simnica.shk/">
                <div className="item">
                  <div className="img-box">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </div>
                  <p>Instagram</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
        <p>
          &copy; <span id="displayDateYear"></span> All Rights Reserved 
          
        </p>
      </div>
</section>

      
    </footer>
  );
};

export default Footer;
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import About from './components/About';
import Footer from './components/Footer';
import Header from './components/Header';
import Services from './components/Service';
import Contact from './components/contactUs';
import Index from './components/index';



function App() {
  return (
    
    <Router>
      <div className="App">
        
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/index" element={<Index />} />
          <Route path="/contact" element={<Contact />} />



          {/* You can add more routes here */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

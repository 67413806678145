import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS
import React from 'react';
import '../assets/styles/Services.css';

const Services = () => {
  return (
    <div>
      <div className="hero_area">
       
          
      </div>

      <section className="service_section layout_padding">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Dienstleistungen</h2>

            <span>
                FIRMA SIMNICA
              </span>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4 mx-auto">
              <div className="box">
                <div className="img-box">
                  <img src="images/heater.png" alt="" />
                </div>
                <div className="detail-box">
                  <h5>Heizung</h5>
                  <p>Unser Heizungswartungsservice sorgt für optimale Leistung und Langlebigkeit Ihres Heizungssystems. Wir führen gründliche Inspektionen und Wartungsarbeiten durch, um Ausfälle zu verhindern, die Effizienz zu steigern und Energiekosten zu senken.</p>
                </div>
              </div>
            </div>
           ` {/* <div className="col-sm-6 col-md-4 mx-auto">
              <div className="box">
                <div className="img-box">
                  <img src="images/air-conditioner.png" alt="" />
                </div>
                <div className="detail-box">
                  <h5>Air Conditioner</h5>
                  <p>We perform maintenance to keep your system running efficiently and reliably detailed inspections and tune-ups to prevent malfunctions, improve performance, and reduce energy costs. Rely on us to maintain a cool and comfortable environment in your home.</p>
                </div>
              </div>
            </div>` */}
            <div className="col-sm-6 col-md-4 mx-auto">
              <div className="box">
                <div className="img-box">
                  <img src="images/s3.png" alt="" />
                </div>
                <div className="detail-box">
                  <h5>Wasser</h5>
                  <p>Unser Wassersystemservice bietet gründliche Wartung, um sicherzustellen, dass Ihre Sanitär-und Wassersysteme reibungslos und effizient funktionieren. Wir führen detaillierte Inspektionen durch, ergreifen vorbeugende Maßnahmen, um Probleme zu vermeiden, und minimieren Reparaturkosten.</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="btn-box">
            <a href="#">View More</a>
          </div> */}
        </div>
      </section>

      
    </div>
  );
}

export default Services;

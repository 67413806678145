import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import '../assets/styles/Header.css';

const Header = ({ title }) => {
  const navigate = useNavigate(); // React Router hook for navigation

  const handleNavigation = (path) => {
    navigate(path); // Navigate to the specified path
  };

  return (
    <header className="header_section">
      <div className="header_top">
        <div className="container-fluid">
          <div className="contact_nav">
            <a href="tel:+1234567890" aria-label="Call us">
              <span>FIRMA SIMNICA</span>
            </a>
            <a href="mailto:simnica-shk@web.de" aria-label="Email us">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              <span>simnica-shk@web.de</span>
            </a>
          </div>
        </div>
      </div>
      <div className="header_bottom">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container">
            <button className="navbar-brand" onClick={() => handleNavigation('/index')}>
              <span>{title}</span>
            </button>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavigation('/index')}>Home</button>
                </li>
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavigation('/about')}>Über uns</button>
                </li>
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavigation('/services')}>Dienstleistungen</button>
                </li>
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavigation('/contact')}>Kontakt</button>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../assets/styles/responsive.css';
import '../assets/styles/style.css';

// JavaScript for Google Maps
// const loadGoogleMaps = () => {
//   const script = document.createElement('script');
//   script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB5ZulwXuB6Biz6WCNpwPvbVksLywMGOoI&callback=myMap`;
//   script.async = true;
//   script.defer = true;
//   document.body.appendChild(script);
// };

const About = () => {
  // useEffect(() => {
  //   loadGoogleMaps();

  //   // Update footer year dynamically
  //   document.getElementById('displayDateYear').textContent = new Date().getFullYear();
  // }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="hero_area">
     
      <section className="about_section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="detail-box">
                <h2>Über uns</h2>
                <p> Willkommen bei Firma SIMNICA - Ihrem zuverlässigen Partner für effiziente Heizlö-sungen! Bei mir finden Sie die passende Lösung für Ihr Heizsystem. Firma Simnica besteht aus hochqualifizierten Fachleuten, die sich leidenschaftlich für ihre Arbeit engagieren. Ich lege großen Wert auf individuelle Beratung und maßgeschneiderte Lösungen, die genau auf Ihre Bedürfnisse abgestimmt sind. Durch den Einsatz modernster Technologien und energiesparender Systeme sorge ich dafür, dass Sie in Ihrem Zuhause oder Ihrem Unternehmen jederzeit ein angenehmes Klima genießen können. Kundenzufriedenheit hat bei mir oberste Priorität. Deshalb setze ich auf hochwertige Produkte, eine sorgfältige Ausführung und einen zuverlässigen Service. Bei Firma SIMNICA bin ich überzeugt, dass eine gut funktionierende Heizung mehr ist, als nur ein technisches Gerät - sie trägt wesentlich zu Ihrem Wohlbefinden bei. Lassen Sie uns gemeinsam für eine behagliche Wärme in Ihrem Zuhause sorgen! 


Kontaktieren Sie mich gerne für ein unverbindliches Beratungsgespräch. Ich freue mich darauf, Ihnen mit Rat und Tat zur Seite zu stehen!</p>
                {/* <a href="#">Read More</a> */}
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="img-box">
                <Slider {...settings}>
                  {/* <div><img src="images/logo.png" alt="About 1" /></div>
                  <div><img src="images/logoMain.jpg" alt="About 1" /></div>
                  <img src="../../images/logoMain.jpg" alt=""/>  */}


                  
                </Slider>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    
      

    </div>
  );
};

export default About;

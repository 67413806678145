import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from 'emailjs-com';
import 'font-awesome/css/font-awesome.min.css';
import React, { useState } from 'react';
import '../assets/styles/style.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [emailStatus, setEmailStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

 
    emailjs.send(
      'service_wouzi2x',        
      'template_c2ndk4l',      
      formData,                 
      'Ruk3976HBPcOZ8R2k'           
    ).then((result) => {
        console.log('Email successfully sent!', result.status, result.text);
        setEmailStatus('Email sent successfully!');
        setFormData({ name: '', phone: '', email: '', message: '' }); 
      }, (error) => {
        console.log('Failed to send email.', error);
        setEmailStatus('Failed to send email.');
      });
  };

  return (
    <div>
      <div className="hero_area"></div>

      <section className="contact_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>kontaktieren Sie uns</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Telefon"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    rows="4"
                  ></textarea>
                </div>
                <div className="d-flex">
                  <button type="submit" className="btn btn-primary">Send</button>
                </div>
              </form>
              {emailStatus && <p>{emailStatus}</p>}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
